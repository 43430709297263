// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-amvac-2022-retailer-summit-interviews-js": () => import("./../../../src/pages/amvac-2022-retailer-summit-interviews.js" /* webpackChunkName: "component---src-pages-amvac-2022-retailer-summit-interviews-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-equipment-simpas-equipment-js": () => import("./../../../src/pages/equipment/simpas-equipment.js" /* webpackChunkName: "component---src-pages-equipment-simpas-equipment-js" */),
  "component---src-pages-equipment-smartbox-js": () => import("./../../../src/pages/equipment/smartbox+.js" /* webpackChunkName: "component---src-pages-equipment-smartbox-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-find-a-provider-js": () => import("./../../../src/pages/find-a-provider.js" /* webpackChunkName: "component---src-pages-find-a-provider-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-portal-index-js": () => import("./../../../src/pages/portal/index.js" /* webpackChunkName: "component---src-pages-portal-index-js" */),
  "component---src-pages-portal-toolkit-js": () => import("./../../../src/pages/portal/toolkit.js" /* webpackChunkName: "component---src-pages-portal-toolkit-js" */),
  "component---src-pages-powerofplus-js": () => import("./../../../src/pages/powerofplus.js" /* webpackChunkName: "component---src-pages-powerofplus-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-simpas-applied-solutions-smartbox-inputs-js": () => import("./../../../src/pages/simpas-applied-solutions/smartbox-inputs.js" /* webpackChunkName: "component---src-pages-simpas-applied-solutions-smartbox-inputs-js" */),
  "component---src-pages-simpas-applied-solutions-solutions-js": () => import("./../../../src/pages/simpas-applied-solutions/solutions.js" /* webpackChunkName: "component---src-pages-simpas-applied-solutions-solutions-js" */),
  "component---src-pages-support-js": () => import("./../../../src/pages/support.js" /* webpackChunkName: "component---src-pages-support-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thank-you-powerofplus-js": () => import("./../../../src/pages/thank-you-powerofplus.js" /* webpackChunkName: "component---src-pages-thank-you-powerofplus-js" */),
  "component---src-pages-thank-you-yearafteryear-js": () => import("./../../../src/pages/thank-you-yearafteryear.js" /* webpackChunkName: "component---src-pages-thank-you-yearafteryear-js" */),
  "component---src-pages-thank-you-yetigiveaway-js": () => import("./../../../src/pages/thank-you-yetigiveaway.js" /* webpackChunkName: "component---src-pages-thank-you-yetigiveaway-js" */),
  "component---src-pages-trademarks-js": () => import("./../../../src/pages/trademarks.js" /* webpackChunkName: "component---src-pages-trademarks-js" */),
  "component---src-pages-yearafteryear-js": () => import("./../../../src/pages/yearafteryear.js" /* webpackChunkName: "component---src-pages-yearafteryear-js" */),
  "component---src-pages-yetigiveaway-js": () => import("./../../../src/pages/yetigiveaway.js" /* webpackChunkName: "component---src-pages-yetigiveaway-js" */)
}

