/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Bootstrap 
require("bootstrap/dist/css/bootstrap.min.css");


// Prevents page from scrolling on back navigation
exports.shouldUpdateScroll = ({
  routerProps: { location }, //location
  getSavedScrollPosition,     //last position on the previous page
}) => {
  if (location.action === 'POP' && location.hash == '') {
    window.setTimeout(() => window.scrollTo(0, 0), 1)
  }
  return false
}